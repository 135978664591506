import { graphql } from 'gatsby';
import * as styles from './header.module.scss';

import React from "react";
import Container from '../container';
import { HeaderImage } from '../post/header';

const PageHeader = ({ title = "Please add a page title", visual, visualMobile, visualMedium, visualLarge, showCoverImage }) => {
  return (
    <div className={styles.header}>
      <Container>
        {showCoverImage && (
          <HeaderImage
            visual={visual}
            visualMobile={visualMobile}
            visualMedium={visualMedium}
            visualLarge={visualLarge}
          />
        )}
        <h1 className={styles.title}>
          <span>{title}</span>
        </h1>
      </Container>
    </div>
  )
};

export default PageHeader;

export const query = graphql`
  fragment PageHeaderImages on CMSPage {
    visualMobile: coverImage {
      fileDe {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: DOMINANT_COLOR
          quality: 55
          width: 355
        )
      }
      fileEn {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: DOMINANT_COLOR
          quality: 55
          width: 355
        )
      }
    }

    visualMedium: coverImage {
      fileDe {
        gatsbyImageData(
          placeholder: DOMINANT_COLOR
          quality: 55
          width: 930
        )
      }
      fileEn {
        gatsbyImageData(
          placeholder: DOMINANT_COLOR
          quality: 55
          width: 930
        )
      }
    }

    visualLarge: coverImage {
      fileDe {
        gatsbyImageData(
          placeholder: DOMINANT_COLOR
          quality: 55
          width: 1110
        )
      }
      fileEn {
        gatsbyImageData(
          placeholder: DOMINANT_COLOR
          quality: 55
          width: 1110
        )
      }
    }
  }
`
