import * as styles from './section.module.scss'

import React from 'react'
import backgroundImage from '../../assets/images/clouds.jpg'
import { translate } from '../../translations'
import HeadlineMain from '../headline/main'
import SearchFormSmall from './form-small'

const SearchSection = ({ locale }) => {
  return (
    <div className={styles.wrapper} style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className={styles.content}>
        <HeadlineMain headline={translate('search.section.headline', locale)} />
        <div className={styles.form}>
          <SearchFormSmall locale={locale} showFilter />
        </div>
      </div>
    </div>
  )
}

export default SearchSection
