import { graphql } from 'gatsby'
import React from "react"
import Home from '../../components/home'
import Layout from '../../components/layout/index'
import PageHeader from '../../components/page/header'
import SectionsRenderer from '../../components/sections/renderer'
import Seo from '../../components/seo'
import { getField } from '../../helper.js'

const Page = ({ data, pageContext: { locale = 'de', pathDe, pathEn } }) => {
  const { page, mostRecentRecipeOrPost, recentRecipesAndPosts } = data

  const title = getField(page, 'title', locale)
  const metaTitle = getField(page, 'metaTitle', locale)
  const metaDescription = getField(page, 'metaDescription', locale)
  const keywords = getField(page, 'keywords', locale)
  const sharingImage = getField(page.coverImage, 'file', locale)?.src
  const visual = getField(page.visual, "file", locale)
  const visualMobile = getField(page.visualMobile, "file", locale)
  const visualMedium = getField(page.visualMedium, "file", locale)
  const visualLarge = getField(page.visualLarge, "file", locale)

  const localizedUrls = {
    de: pathDe,
    en: pathEn
  }

  // TODO: use posts, podcasts & recipes for recent stuff

  const getHome = () => {
    return (
      <Home
        locale={locale}
        mostRecentRecipeOrPost={mostRecentRecipeOrPost}
        recentRecipesAndPosts={recentRecipesAndPosts}
      />
    )
  }

  return (
    <>
      <Seo
        locale={locale}
        localizedUrls={localizedUrls}
        title={metaTitle}
        sharingTitle={title}
        sharingDescription={metaDescription}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        keywords={keywords}
        image={sharingImage}
      />
      <Layout locale={locale} localizedUrls={localizedUrls}>
        {(page.slugDe !== 'home' && (
          <PageHeader
            title={title}
            visual={visual}
            visualMobile={visualMobile}
            visualMedium={visualMedium}
            visualLarge={visualLarge}
            showCoverImage={page.showCoverImage}
          />
        ))}
        {(page.slugDe === 'home' && getHome())}
        <div>
          <SectionsRenderer sections={page?.sections} locale={locale} />
        </div>
      </Layout>
    </>
  )
}

// <SearchSection locale={locale}/>

export default Page

export const query = graphql`
  fragment PageFragment on CMSPage {
    __typename
    id
    titleDe
    slugDe
    titleEn
    metaTitleDe
    metaTitleEn
    metaDescriptionDe
    metaDescriptionEn
    keywordsDe
    keywordsEn
    coverImage {
      fileDe {
        src
      }
      fileEn {
        src
      }
    }
    ...PageHeaderImages
    showCoverImage
    sections {
      __typename
      ...Banner
      ...CardGrid
      ...CategoriesGrid
      ...HtmlCode
      ...Photos
      ...PostAndRecipeGrid
      ...Promo
      ...SeriesListing
      ...Text
      ...YoutubePlayer
    }
  }

  query PageQuery($id: String) {
    page: cmsPage(id: {eq: $id}) {
      ...PageFragment
    }

    mostRecentRecipeOrPost: recentRecipesAndPosts(limit: 1) {
      ... on CMSPost {
        ...RecentPost
      }
      ... on CMSRecipe {
        ...RecentRecipe
      }
    }

    recentRecipesAndPosts(limit: 10, skip: 1) {
      ... on CMSPost {
        ...PromoPost
      }
      ... on CMSRecipe {
        ...PromoRecipe
      }
    }
  }
`
