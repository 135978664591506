// extracted by mini-css-extract-plugin
export var desktop = "recent-module--desktop--3gv6u";
export var wrapper = "recent-module--wrapper--YdgG1";
export var post = "recent-module--post--yVu7d";
export var visual = "recent-module--visual--fk1DC";
export var ad = "recent-module--ad--jJ8UE";
export var titleWrapper = "recent-module--titleWrapper--sVIGN";
export var title = "recent-module--title--E-H29";
export var teaser = "recent-module--teaser--Ym+NS";
export var description = "recent-module--description--hm1RZ";
export var more = "recent-module--more--waSTY";
export var fadein = "recent-module--fadein--rC2hx";