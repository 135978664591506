import * as styles from './recent.module.scss'

import { Link, graphql } from 'gatsby'

import { GatsbyImage } from 'gatsby-plugin-image'
import React from "react"
import { getField } from '../../helper.js'
import { pathTo } from '../../routes'
import { translate } from '../../translations'
import TextRenderer from '../text/renderer'
import AdBadge from './ad'

const RecentPost = ({ post, locale }) => {
  if (post === null) return null
  const title = getField(post, 'title', locale)
  const teaser = getField(post, 'teaser', locale)
  const coverImage = getField(post.image, 'file', locale)
  const path = pathTo({ ...post, locale })
  const type = post.__typename.replace('CMS', '').toLowerCase()

  return (
    <div className={styles.desktop}>
      <div className={styles.wrapper}>
        <Link to={path} className={styles.post}>
          {(coverImage &&
            <div className={styles.visual}>
              <GatsbyImage
                image={coverImage?.gatsbyImageData}
                alt={title}
              />
            </div>
          )}
          <div className={styles.titleWrapper} data-is-ad={post.advertisement}>
            {(post.advertisement &&
              <div className={styles.ad}>
                <AdBadge locale={locale} />
              </div>
            )}
            {(title &&
              <div className={styles.title}>
                <h2><span>{title}</span></h2>
              </div>
            )}
            {(teaser &&
              <div className={styles.description}>
                <TextRenderer text={teaser} />
              </div>
            )}
            <div className={styles.more}>
              {translate(`home.recentPost.buttonLabel.${type}`, locale)}
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default RecentPost

export const query = graphql`
  fragment RecentRecipe on CMSRecipe {
    __typename
    id
    titleDe
    titleEn
    slugDe
    slugEn
    teaserDe
    teaserEn
    advertisement
    ...RecentRecipeImage
  }

  fragment RecentPost on CMSPost {
    __typename
    id
    titleDe
    titleEn
    slugDe
    slugEn
    teaserDe
    teaserEn
    advertisement
    ...RecentPostImage
    series {
      __typename
      slugDe
      slugEn
    }
  }

  fragment RecentRecipeImage on CMSRecipe {
    image: coverImage {
      titleDe
      titleEn
      fileDe {
        gatsbyImageData(
          height: 555
          quality: 75
          fit: "thumb"
          placeholder: DOMINANT_COLOR
          width: 555
        )
      }
      fileEn {
        gatsbyImageData(
          height: 555
          quality: 75
          fit: "thumb"
          placeholder: DOMINANT_COLOR
          width: 555
        )
      }
    }
  }

  fragment RecentPostImage on CMSPost {
    image: coverImage {
      titleDe
      titleEn
      fileDe {
        gatsbyImageData(
          height: 555
          quality: 75
          fit: "thumb"
          placeholder: DOMINANT_COLOR
          width: 555
        )
      }
      fileEn {
        gatsbyImageData(
          height: 555
          quality: 75
          fit: "thumb"
          placeholder: DOMINANT_COLOR
          width: 555
        )
      }
    }
  }
`
