import * as styles from './index.module.scss'

import { Link } from 'gatsby'
import React from 'react'
import { translate } from '../../translations'
import RecentPost from '../post/recent'
import Promo from '../promo'
import SearchSection from '../search/section'

const Home = ({ mostRecentRecipeOrPost, recentRecipesAndPosts, locale }) => {
  const link = locale === 'de' ? '/de/archiv/1' : '/en/archive/1'

  return (
    <div className={styles.wrapper}>
      <RecentPost post={mostRecentRecipeOrPost[0]} locale={locale} />
      <Promo
        titleDe={translate('home.posts.headline', "de")}
        titleEn={translate('home.posts.headline', "en")}
        items={recentRecipesAndPosts}
        locale={locale}
        actions={(
          <Link to={link}>{translate('general.showAllShort', locale)}</Link>
        )}
      />
      <SearchSection locale={locale} />
    </div>
  )
}

export default Home

